import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"

import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import ym from "react-yandex-metrika"

class Prices extends React.Component {

  componentDidMount() {
    ym("hit", "/declarations");
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Декларации EAC- Исполиния</title>
          <meta name="description"
                content="Базовый прайс-лист на продукцию RBM
Прайс-лист компании ИСПОЛИНИЯ, официального дилера RBM в России" />

          <meta property="og:title" content="Базовый прайс-лист" />
          <meta property="og:description"
                content="Базовый прайс-лист на продукцию RBM
Прайс-лист компании ИСПОЛИНИЯ, официального дилера RBM в России" />
        </Helmet>

        <Header />
        <main>
          <Container>
            <div>
              <div className="buttons declaration-full">
                <Link
                  to="../declaration-filters.pdf" download>
                  <button className="col-md">Скачать <span className="DS">ДС</span><span className="DS-long">декларацию соответствия</span> на фильтры</button>
                </Link>
                <Link
                  to={"../declaration-others.pdf"} download>
                  <button className="col-md">Скачать <span className="DS">ДС</span><span className="DS-long">декларацию соответствия</span> на остальное</button>
                </Link>
              </div>
            </div>
            <iframe title="Базовый прайс лист" src="../declaration-all.pdf" width="100%" height="1000px">К сожалению, ваш браузер не
              поддерживает отображение
              каталога на
              сайте. Пожалуйста, скачайте его по ссылке выше.
            </iframe>
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}

export default Prices